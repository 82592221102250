import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: () => import('../MainLayout.vue'),
    redirect: '/firstFraft',
    children: [{
        path: '/test1',
        name: 'test1',
        component: () => import('../views/Test1View.vue')
      },
      {
        path: '/test2',
        name: 'test2',
        component: () => import('../views/Test2View.vue')
      },
      {
        path: '/firstFraft',
        name: 'firstFraft',
        component: () => import('../views/FirstDraftView.vue')
      },
      {
        path: '/paperPrepare',
        name: 'paperPrepare',
        component: () => import('../views/PaperPrepareView.vue')
      },
      {
        path: '/optimize',
        name: 'optimize',
        component: () => import('../views/OptimizeView.vue')
      },
      {
        path: '/documentSearch/:id?',
        name: 'documentSearch',
        component: () => import('../views/DocumentSearchView.vue')
      },
      {
        path: '/mindMapping',
        name: 'mindMapping',
        component: () => import('../views/MindMappingView.vue')
      },
      {
        path: '/AIDetect',
        name: 'AIDetect',
        component: () => import('../views/AIDetectView.vue')
      },
      {
        path: '/upload',
        name: 'upload',
        component: () => import('../views/UploadView.vue')
      },
      {
        path: '/download',
        name: 'download',
        component: () => import('../views/DownloadView.vue')
      },
      {
        path: '/form',
        name: 'form',
        component: () => import('../views/FormView.vue')
      },
      {
        path: '/balance',
        name: 'balance',
        component: () => import('../views/BalanceView.vue')
      },
      {
        path: '/recharge',
        name: 'recharge',
        component: () => import('../views/RechargeView.vue')
      },
      {
        path: '/share',
        name: 'share',
        component: () => import('../views/ShareView.vue')
      },
      {
        path: '/zida-master',
        name: 'ZidaMaster',
        component: () => import('../views/ZidaMaster.vue')
      },
    ]
  },



  // {
  //   path: '/test1',
  //   name: 'test1',
  //   component: () => import('../views/Test1View.vue')
  // },
  // {
  //   path: '/test2',
  //   name: 'test2',
  //   component: () => import('../views/Test2View.vue')
  // },
  // {
  //   path: '/firstFraft',
  //   name: 'firstFraft',
  //   component: () => import('../views/FirstDraftView.vue')
  // },
  // {
  //   path: '/paperPrepare',
  //   name: 'paperPrepare',
  //   component: () => import('../views/PaperPrepareView.vue')
  // },
  // {
  //   path: '/optimize',
  //   name: 'optimize',
  //   component: () => import('../views/OptimizeView.vue')
  // },
  // {
  //   path: '/formulaFormatting',
  //   name: 'formulaFormatting',
  //   component: () => import('../views/PayHomeView.vue')
  // },
  // {
  //   path: '/documentSearch',
  //   name: 'documentSearch',
  //   component: () => import('../views/DocumentSearchView.vue')
  // },
  // {
  //   path: '/mindMapping',
  //   name: 'mindMapping',
  //   component: () => import('../views/MindMappingView.vue')
  // },
  // {
  //   path: '/AIDetect',
  //   name: 'AIDetect',
  //   component: () => import('../views/AIDetectView.vue')
  // },
  // {
  //   path: '/upload',
  //   name: 'upload',
  //   component: () => import('../views/UploadView.vue')
  // }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = `${Vue.prototype.$config.appName}-SCI级论文助手`
  }

  next()
})


export default router
